// eslint-disable-next-line no-unused-vars
import React from 'react'
import parse from 'html-react-parser';

export default function agentsection() {
  return (
   <>
  
<section className=" bg-gray-100">
  <div className="mx-auto max-w-screen-xl p-4 sm:px-6">
    <div className="grid grid-cols-1 gap-x-12 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-4">
        <p className="max-w-xl text-lg">
        <img src="https://res.cloudinary.com/dailyle-company/image/upload/v1705171434/Dailyle%20Website/Delivery%20Agent/Instant_Delivery_platform_1.png" className="h-90 w-full 
    rounded --300" alt="" /> 
        </p>
        <ul><br></br>
  <li>
    <a
      href="tel:917894447766"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-red-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>



      <span className="text-sm font-medium"> 
       <a href="" className='text-pink-500 text-[14px]' > Choose Your Work Time 
 </a> </span>
    </a>
  </li>

  <li>
    <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="m6.115 5.19.319 1.913A6 6 0 0 0 8.11 10.36L9.75 12l-.387.775c-.217.433-.132.956.21 1.298l1.348 1.348c.21.21.329.497.329.795v1.089c0 .426.24.815.622 1.006l.153.076c.433.217.956.132 1.298-.21l.723-.723a8.7 8.7 0 0 0 2.288-4.042 1.087 1.087 0 0 0-.358-1.099l-1.33-1.108c-.251-.21-.582-.299-.905-.245l-1.17.195a1.125 1.125 0 0 1-.98-.314l-.295-.295a1.125 1.125 0 0 1 0-1.591l.13-.132a1.125 1.125 0 0 1 1.3-.21l.603.302a.809.809 0 0 0 1.086-1.086L14.25 7.5l1.256-.837a4.5 4.5 0 0 0 1.528-1.732l.146-.292M6.115 5.19A9 9 0 1 0 17.18 4.64M6.115 5.19A8.965 8.965 0 0 1 12 3c1.929 0 3.716.607 5.18 1.64" />
</svg>



      <span className="text-sm font-medium"> 
       <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148" className='text-pink-500 text-[14px]' >   <address className="mt-2 not-italic">  Explore Location  </address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
</svg>



      <span className="text-sm font-medium">  
       <a href="mailto:contact@dailyle.com" className='text-pink-500 text-[15px]' > Earn Money From  Day 1 </a> </span>
    </a>
  </li>
 
 
</ul>
 
      </div>

      <div className="rounded-lg bg-transparent p-3 shadow-lg lg:col-span-3 lg:p-1">
         
      <ul>
  {parse(`
    <p> <script src="https://cdn02.jotfor.ms/static/prototype.forms.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/static/jotform.forms.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/js/vendor/maskedinput_5.0.9.min.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn02.jotfor.ms/js/vendor/imageinfo.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/file-uploader/fileuploader.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/js/punycode-1.4.1.min.js?v=3.3.53666" type="text/javascript" defer></script>
    <script src="https://cdn01.jotfor.ms/s/umd/809a81f65a7/for-form-branding-footer.js?v=3.3.53666" type="text/javascript" defer></script>
    <script src="https://cdn02.jotfor.ms/js/vendor/smoothscroll.min.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/js/errorNavigation.js?v=3.3.53666" type="text/javascript"></script>
    
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/stylebuilder/static/form-common.css?v=a856274
    "/>
    <style type="text/css">@media print{*{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;}.form-section{display:inline!important}.form-pagebreak{display:none!important}.form-section-closed{height:auto!important}.page-section{position:initial!important}}</style>
    <link type="text/css" rel="stylesheet" href="https://cdn02.jotfor.ms/themes/CSS/5e6b428acc8c4e222d1beb91.css?v=3.3.53666&themeRevisionID=5f30e2a790832f3e96009402"/>
    <link type="text/css" rel="stylesheet" href="https://cdn03.jotfor.ms/css/styles/payment/payment_styles.css?3.3.53666" />
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/css/styles/payment/payment_feature.css?3.3.53666" />
    <style type="text/css" id="form-designer-style">
        /* Injected CSS Code */
    .form-label.form-label-auto {
            
          display: block;
          float: none;
          text-align: left;
          width: 100%;
        
          }
        /* Injected CSS Code */
    </style>
    
    <form class="jotform-form" onsubmit="return typeof testSubmitFunction !== 'undefined' && testSubmitFunction();" action="https://submit.jotform.com/submit/230384581747463" method="post" enctype="multipart/form-data" name="form_230384581747463" id="230384581747463" accept-charset="utf-8" autocomplete="on"><input type="hidden" name="formID" value="230384581747463" /><input type="hidden" id="JWTContainer" value="" /><input type="hidden" id="cardinalOrderNumber" value="" /><input type="hidden" id="jsExecutionTracker" name="jsExecutionTracker" value="build-date-1715068141579" /><input type="hidden" id="submitSource" name="submitSource" value="unknown" /><input type="hidden" id="buildDate" name="buildDate" value="1715068141579" />
      <div role="main" class="form-all">
        <ul class="form-section page-section">
          <li class="form-line jf-required" data-type="control_fullname" id="id_8"><label class="form-label form-label-top form-label-auto" id="label_8" for="first_8" aria-hidden="false"> Full Name<span class="form-required">*</span> </label>
            <div id="cid_8" class="form-input-wide jf-required" data-layout="full">
              <div data-wrapper-react="true"><span class="form-sub-label-container" style="vertical-align:top" data-input-type="first"><input type="text" id="first_8" name="q8_fullName[first]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 given-name" size="10" data-component="first" aria-labelledby="label_8 sublabel_8_first" required="" value="" /><label class="form-sub-label" for="first_8" id="sublabel_8_first" style="min-height:13px">First Name</label></span><span class="form-sub-label-container" style="vertical-align:top" data-input-type="last"><input type="text" id="last_8" name="q8_fullName[last]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 family-name" size="15" data-component="last" aria-labelledby="label_8 sublabel_8_last" required="" value="" /><label class="form-sub-label" for="last_8" id="sublabel_8_last" style="min-height:13px">Last Name</label></span></div>
            </div>
          </li>
          <li class="form-line" data-type="control_address" id="id_9" data-compound-hint=",,,,Please Select,,Please Select,"><label class="form-label form-label-top form-label-auto" id="label_9" for="input_9_addr_line1" aria-hidden="false"> Address </label>
            <div id="cid_9" class="form-input-wide" data-layout="full">
              <div summary="" class="form-address-table jsTest-addressField">
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-street-line jsTest-address-lineField"><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_addr_line1" name="q9_address[addr_line1]" class="form-textbox form-address-line" data-defaultvalue="" autoComplete="section-input_9 address-line1" data-component="address_line_1" aria-labelledby="label_9 sublabel_9_addr_line1" required="" value="" /><label class="form-sub-label" for="input_9_addr_line1" id="sublabel_9_addr_line1" style="min-height:13px">Street Address</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField" style="display:none"><span class="form-address-line form-address-street-line jsTest-address-lineField"><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_addr_line2" name="q9_address[addr_line2]" class="form-textbox form-address-line" data-defaultvalue="" autoComplete="section-input_9 off" data-component="address_line_2" aria-labelledby="label_9 sublabel_9_addr_line2" value="" /><label class="form-sub-label" for="input_9_addr_line2" id="sublabel_9_addr_line2" style="min-height:13px">Street Address Line 2</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-city-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_city" name="q9_address[city]" class="form-textbox form-address-city" data-defaultvalue="" autoComplete="section-input_9 address-level2" data-component="city" aria-labelledby="label_9 sublabel_9_city" required="" value="" /><label class="form-sub-label" for="input_9_city" id="sublabel_9_city" style="min-height:13px">City</label></span></span><span class="form-address-line form-address-state-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_state" name="q9_address[state]" class="form-textbox form-address-state" data-defaultvalue="" autoComplete="section-input_9 address-level1" data-component="state" aria-labelledby="label_9 sublabel_9_state" value="" /><label class="form-sub-label" for="input_9_state" id="sublabel_9_state" style="min-height:13px">State / Province</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-zip-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_postal" name="q9_address[postal]" class="form-textbox form-address-postal" data-defaultvalue="" autoComplete="section-input_9 postal-code" data-component="zip" aria-labelledby="label_9 sublabel_9_postal" required="" value="" /><label class="form-sub-label" for="input_9_postal" id="sublabel_9_postal" style="min-height:13px">Postal / Zip Code</label></span></span></div>
              </div>
            </div>
          </li>
          <li class="form-line" data-type="control_phone" id="id_10"><label class="form-label form-label-top form-label-auto" id="label_10" for="input_10_full"> Phone Number </label>
            <div id="cid_10" class="form-input-wide" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="tel" id="input_10_full" name="q10_phoneNumber[full]" data-type="mask-number" class="mask-phone-number form-textbox validate[Fill Mask]" data-defaultvalue="" autoComplete="section-input_10 tel-national" style="width:310px" data-masked="true" placeholder="(000) 000-0000" data-component="phone" aria-labelledby="label_10 sublabel_10_masked" value="" /><label class="form-sub-label" for="input_10_full" id="sublabel_10_masked" style="min-height:13px">Please enter a valid phone number.</label></span> </div>
          </li>
          <li class="form-line" data-type="control_fileupload" id="id_11"><label class="form-label form-label-top form-label-auto" id="label_11" for="input_11" aria-hidden="false"> Upload Driving Licence </label>
            <div id="cid_11" class="form-input-wide" data-layout="full">
              <div class="jfQuestion-fields" data-wrapper-react="true">
                <div class="jfField isFilled">
                  <div class="jfUpload-wrapper">
                    <div class="jfUpload-container">
                      <div class="jfUpload-button-container">
                        <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                          <div class="jfUpload-heading forMobile">Choose a file</div>
                        </div>
                      </div>
                    </div>
                    <div class="jfUpload-files-container">
                      <div class="validate[multipleUpload]"><input type="file" id="input_11" name="q11_uploadDriving[]" multiple="" class="form-upload-multiple" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif" data-file-maxsize="10854" data-file-minsize="0" data-file-limit="" data-component="fileupload" aria-label="Browse Files" /></div>
                    </div>
                  </div>
                  <div data-wrapper-react="true"></div>
                </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
              </div>
            </div>
          </li>
          <li class="form-line" data-type="control_fileupload" id="id_14"><label class="form-label form-label-top form-label-auto" id="label_14" for="input_14" aria-hidden="false"> Upload Aadhar Card </label>
            <div id="cid_14" class="form-input-wide" data-layout="full">
              <div class="jfQuestion-fields" data-wrapper-react="true">
                <div class="jfField isFilled">
                  <div class="jfUpload-wrapper">
                    <div class="jfUpload-container">
                      <div class="jfUpload-button-container">
                        <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                          <div class="jfUpload-heading forMobile">Choose a file</div>
                        </div>
                      </div>
                    </div>
                    <div class="jfUpload-files-container">
                      <div class="validate[multipleUpload]"><input type="file" id="input_14" name="q14_uploadAadhar14[]" multiple="" class="form-upload-multiple" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif" data-file-maxsize="10854" data-file-minsize="0" data-file-limit="" data-component="fileupload" aria-label="Browse Files" /></div>
                    </div>
                  </div>
                  <div data-wrapper-react="true"></div>
                </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
              </div>
            </div>
          </li>
          <li class="form-line" data-type="control_fileupload" id="id_13"><label class="form-label form-label-top form-label-auto" id="label_13" for="input_13" aria-hidden="false"> Upload PAN Card </label>
            <div id="cid_13" class="form-input-wide" data-layout="full">
              <div class="jfQuestion-fields" data-wrapper-react="true">
                <div class="jfField isFilled">
                  <div class="jfUpload-wrapper">
                    <div class="jfUpload-container">
                      <div class="jfUpload-button-container">
                        <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                          <div class="jfUpload-heading forMobile">Choose a file</div>
                        </div>
                      </div>
                    </div>
                    <div class="jfUpload-files-container">
                      <div class="validate[multipleUpload]"><input type="file" id="input_13" name="q13_uploadPan[]" multiple="" class="form-upload-multiple" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif" data-file-maxsize="10854" data-file-minsize="0" data-file-limit="" data-component="fileupload" aria-label="Browse Files" /></div>
                    </div>
                  </div>
                  <div data-wrapper-react="true"></div>
                </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
              </div>
            </div>
          </li>
          <li class="form-line jf-required" data-type="control_email" id="id_6"><label class="form-label form-label-top form-label-auto" id="label_6" for="input_6" aria-hidden="false"> E-mail<span class="form-required">*</span> </label>
            <div id="cid_6" class="form-input-wide jf-required" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="email" id="input_6" name="q6_email6" class="form-textbox validate[required, Email]" data-defaultvalue="" autoComplete="section-input_6 email" style="width:310px" size="310" placeholder="ex: myname@example.com" data-component="email" aria-labelledby="label_6 sublabel_input_6" required="" value="" /><label class="form-sub-label" for="input_6" id="sublabel_input_6" style="min-height:13px">example@example.com</label></span> </div>
          </li>
          <li class="form-line jf-required" data-type="control_textarea" id="id_4"><label class="form-label form-label-top form-label-auto" id="label_4" for="input_4" aria-hidden="false"> Describe Your Message :<span class="form-required">*</span> </label>
            <div id="cid_4" class="form-input-wide jf-required" data-layout="full"> <textarea id="input_4" class="form-textarea validate[required]" name="q4_describeYour" style="width:648px;height:163px" data-component="textarea" required="" aria-labelledby="label_4"></textarea> </div>
          </li>
          <li class="form-line" data-type="control_button" id="id_2">
            <div id="cid_2" class="form-input-wide" data-layout="full">
              <div data-align="center" class="form-buttons-wrapper form-buttons-center   jsTest-button-wrapperField"><button id="input_2" type="submit" class="form-submit-button submit-button jf-form-buttons jsTest-submitField" data-component="button" data-content="">Submit Now</button></div>
            </div>
          </li>
          <li style="display:none">Should be Empty: <input type="text" name="website" value="" type="hidden" /></li>
        </ul>
      </div>
      <script>
        JotForm.showJotFormPowered = "new_footer";
      </script>
      <script>
        JotForm.poweredByText = "Powered by Jotform";
      </script><input type="hidden" class="simple_spc" id="simple_spc" name="simple_spc" value="230384581747463" />
      <script type="text/javascript">
        var all_spc = document.querySelectorAll("form[id='230384581747463'] .si" + "mple" + "_spc");
        for (var i = 0; i < all_spc.length; i++)
        {
          all_spc[i].value = "230384581747463-230384581747463";
        }
      </script>
    </form><script type="text/javascript">JotForm.ownerView=true;</script><script type="text/javascript">JotForm.isNewSACL=true;</script>   </p>
  `)}
</ul>

      </div>
    </div>
  </div>
</section>
   </>
  )
}
 