/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
export default function Ourservice() {
  return (
   <>
   
  <section className="mb-[8px]">
  <div class="mx-auto">
      <div  class="mb-[4px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> What We Delivered  </h3>
        </div>
      </div>

      <Carousel responsive={responsive}  className="z-10 overscroll-x-none P-3" >
  <div className="p-6"><Card className=" w-96">
  
      <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864194/Banners/Catlogs/8_wjtnw9.jpg" className="p-3 h-190 w-full 
    rounded --300" alt="" /> 
      
    </Card>
 </div>


 <div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864193/Banners/Catlogs/7_u3ywgt.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>


<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864193/Banners/Catlogs/5_k2lyiq.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>


<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864199/Banners/Catlogs/fish_fyi5uz.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>

<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864192/Banners/Catlogs/3_y5kpiw.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>


<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864192/Banners/Catlogs/6_w7tozj.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>

<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864192/Banners/Catlogs/4_erszdw.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>

<div className="p-6"><Card className=" w-96">
  
  <img src="https://res.cloudinary.com/dailyleadmin/image/upload/v1629864196/Banners/Catlogs/9_anncm6.jpg" className="p-3 h-190 w-full 
rounded --300" alt="" /> 
  
</Card>
</div>

   
  
</Carousel>

  </section>

  
   
   </>
  )
}
 