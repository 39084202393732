/* eslint-disable react/no-unknown-property */
import { Accordion } from 'flowbite-react';

export default function Faqsection() {
  return (
   <>
   <br></br>
   <div class="mx-auto">
      <div  class="mb-[2px] text-gray-600 body-font  ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> FAQ  </h3>
        </div>
      </div>

  <Accordion className="p-12" >
      <Accordion.Panel >
        <Accordion.Title className=" text-blue-700" >What is Dailyle ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Dailyle is Instant Delivery Platform Of Less than 1 Hour.
We Provide Multiple Categories Delivery at Doorstep under 10 Km like Food, Vegetables, Cakes, Sweets, Fruits, Raw Chicken, Raw Fish, Gifts Etc.
Our Mission is to Create Less than 1 Hour Delivery ecosystem worldwide.
          </p>
           
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className=" text-blue-700" > Why Dailyle Exist ?</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
            
Our Purpose is to Make Survival Easy On Earth. So that Future Generations of humanity will be Focused on Success and Innovation not only their Survival Part of Life.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>

        <Accordion.Title className=" text-blue-700" >  How To Join Delivery Agent at Dailyle ?  </Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 text-gray-500 dark:text-gray-400">
          Just Visit <a className='font-bold  text-pink-600' href='/Deliveryagent'>
            Join As Delivery Agent  </a>Fill The Form & Get Update Soon For Registration From Us. 
         
          </p>
            
           
        </Accordion.Content>
      </Accordion.Panel>

      <Accordion.Panel>

<Accordion.Title className=" text-blue-700" >  How To Join Merchant Or Store / Partner Listing ?  </Accordion.Title>
<Accordion.Content>
  <p className="mb-2 text-gray-500 dark:text-gray-400">
  Just Visit <a className='font-bold text-[16px] text-pink-600' href='/StoreMerchant'>
    Join As Merchant Listing  </a>Fill The Form & Get Update Soon For Registration From Us. 
 
  </p>
    
   
</Accordion.Content>
</Accordion.Panel>


<Accordion.Panel>

<Accordion.Title className=" text-blue-700" > What is Dailyle Wallet Point & About Wallet Points Earning  ?  </Accordion.Title>
<Accordion.Content>
  <p className="mb-2 text-gray-500 dark:text-gray-400">
  Dailyle Wallet Points is just a Reward Point that you get rewarded whenever you  Place your order in Dailyle App. Dailyle Wallets Points Calculated <a className='font-bold text-[16px] text-purple-600'>
  10 Points = ₹1   </a> <br></br> So, Get Point on Every Order In Dailyle App  
 
  </p>
    
   
</Accordion.Content>
</Accordion.Panel>


    </Accordion>
   </>
  )
}
 