/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-no-undef */
import { Card } from 'flowbite-react';

export default function Feature() {
  return (
   <> 
 <section class="text-gray-600 body-font">
  <div class="container px-5 py-2 mx-auto">
    <div class="flex flex-wrap -m-4 text-center">
      <div class="p-4 sm:w-1/4 w-1/2">
      <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
           
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="https://res.cloudinary.com/dailyleadmin/image/upload/v1675840803/Assets/Icons/order_xh6sde.png"
      alt="pic"
    />
          </div>
          
          <h6 className="mb-2 text-[12px] font-bold leading-5 tracking-wider uppercase">
          1. Place Your Order Anytime
          </h6>
           
          </Card>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="https://res.cloudinary.com/dailyleadmin/image/upload/v1675839339/Assets/Icons/track_cb4v8m.png"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] font-bold leading-5 tracking-wider uppercase">
          2.Track Location Realtime 
          </h6>
         
          </Card>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="https://res.cloudinary.com/dailyleadmin/image/upload/v1675840108/Assets/Icons/location_3_p6gvs3.png"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] font-bold leading-5 tracking-wider uppercase">
         3.  Get Delivered at Door 
          </h6> 
          </Card>
      </div>
      <div class="p-4 sm:w-1/4 w-1/2">
      <Card className=" " imgSrc="/images/blog/image-4.jpg" >
          <div className="flex items-center justify-center w-10 h-10 mx-auto mb-4 rounded-full bg-indigo-50 sm:w-12 sm:h-12">
          <img
      className="h-50 w-55 rounded-lg object-cover object-center"
      src="https://res.cloudinary.com/dailyleadmin/image/upload/v1675840293/Assets/Icons/testimonial_lvpx2j.png"
      alt="pic"
    />
          </div>
          <h6 className="mb-2 text-[12px] font-bold leading-5 tracking-wider uppercase">
          4. Enjoy | Give Feedback
 
          </h6>
          
          </Card>
      </div>
    </div>
  </div>
</section>

 
  </>
  )
}
  