/* eslint-disable react/no-unknown-property */
  
import Navbarsection from "../views/Home/Navbarsection"
import Bannerslides from "../views/Home/Bannerslides"  
import Footersection from "../views/Home/Footersection"
import Blogsection from "../views/Home/Blogsection"
import Faqsection from "../views/Home/Faqsection" 
import Feature from "../views/Home/Feature"
import Joinsection from "../views/Join/Joinsection"
import Ourservice from "../views/Home/Ourservice" 
import Download from "../views/Home/Download"
 
export default function Home() {
  return (
<>
 
<Navbarsection />
<Bannerslides />
<Feature/>
<Joinsection/>
<Ourservice/>
<Faqsection /> 
<Blogsection />
<Download/>

<Footersection />
  
</>
  )
}
 