  
import Navbarsection from "../views/Home/Navbarsection" 
  
import Footersection from "../views/Home/Footersection"
 import Merchantsections from "../views/Join/Merchantsections"
   
export default function StoreMerchant() {
  return (
<>
 
<Navbarsection />
 
 <Merchantsections/>
 
<Footersection />
 
  
  
</>
  )
}


