 
import ScrollToTop from "react-scroll-to-top";

export default function Categoryslides() {
  return (
  <>

  <div className="p-4	top-3 flex items-stretch overflow-x-auto space-x-8 w-/2 bg-blue-900	text-slate-200">

<section className="flex-shrink-0 ">
  
    <span ><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/fish.png?updatedAt=1702638370419" className="h-16 w-16 
    rounded --300" alt="" />  </span> 
     <span className="text-white font-bold text-slate-200 flex items-stretch center" > Fish </span>
  </section>
  <section className="flex-shrink-0 rounded p-1 border-2 border-indigo-300 text-slate-200">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/egg.png?updatedAt=1702638369918" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white font-bold text-slate-200 flex items-stretch center" > Eggs </span>
  </section>
  <section className="flex-shrink-0">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/Dry%20Fish.png?updatedAt=1702638369893" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch" > Dry Fish </span>
  </section>
  <section className="flex-shrink-0">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/sea%20food.png?updatedAt=1702638369923" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch center "> Sea Food </span>
  </section>
  <section className="flex-shrink-0 ">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/frozen.png?updatedAt=1702638369897" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch center"> Frozen </span>
  </section>
  <section className="flex-shrink-0">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/chicken.png?updatedAt=1702638370424" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch" > Meat </span>
  </section>
  <section className="flex-shrink-0">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/pickle.png?updatedAt=1702638369925" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch"> Pickles </span>
  </section>
  
  <section className="flex-shrink-0 ">
    <span><img src="https://ik.imagekit.io/dhubapp/GoExpert/BhaaratFresh/catlogpic/others.png?updatedAt=1702638370428" className="h-16 w-16 
    rounded --300" alt=""/></span>
    <span className="text-white text-slate-200 font-bold flex items-stretch"> Special </span>
  </section>

</div>

<div/>
      <ScrollToTop smooth />
  </>
  )
}
 