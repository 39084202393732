import Footersection from "../views/Home/Footersection";
import Navbarsection from "../views/Home/Navbarsection";

import LocationBody from "../views/Location/LocationBody";

 
export default function Location() {
  return (
   <> 
 <Navbarsection />
<LocationBody />
 <Footersection />
   </>
  )
}
