/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
  
import Navbarsection from "../views/Home/Navbarsection" 
  import Footersection from "../views/Home/Footersection"
  import parse from 'html-react-parser';

export default function Category() {
  return (
<>
 
<Navbarsection />
 
        <div className="rounded-lg bg-transparent ">
         
        <ul>
  {parse(`
    <p> <script src="https://cdn02.jotfor.ms/static/prototype.forms.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/static/jotform.forms.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/js/vendor/maskedinput_5.0.9.min.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn02.jotfor.ms/js/punycode-1.4.1.min.js?v=3.3.53666" type="text/javascript" defer></script>
    <script src="https://cdn03.jotfor.ms/js/vendor/imageinfo.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/file-uploader/fileuploader.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/s/umd/88eb846b2ef/for-form-branding-footer.js?v=3.3.53666" type="text/javascript" defer></script>
    <script src="https://cdn02.jotfor.ms/js/vendor/smoothscroll.min.js?v=3.3.53666" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/js/errorNavigation.js?v=3.3.53666" type="text/javascript"></script>
    
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/stylebuilder/static/form-common.css?v=a856274
    "/>
    <style type="text/css">@media print{*{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;}.form-section{display:inline!important}.form-pagebreak{display:none!important}.form-section-closed{height:auto!important}.page-section{position:initial!important}}</style>
    <link type="text/css" rel="stylesheet" href="https://cdn02.jotfor.ms/themes/CSS/5e6b428acc8c4e222d1beb91.css?v=3.3.53666&themeRevisionID=5f30e2a790832f3e96009402"/>
    <link type="text/css" rel="stylesheet" href="https://cdn03.jotfor.ms/css/styles/payment/payment_styles.css?3.3.53666" />
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/css/styles/payment/payment_feature.css?3.3.53666" />
    <style type="text/css" id="form-designer-style">
        /* Injected CSS Code */
    .form-label.form-label-auto {
            
          display: block;
          float: none;
          text-align: left;
          width: 100%;
        
          }
        /* Injected CSS Code */
    </style>
    
    <form class="jotform-form" onsubmit="return typeof testSubmitFunction !== 'undefined' && testSubmitFunction();" action="https://submit.jotform.com/submit/230385039903456" method="post" enctype="multipart/form-data" name="form_230385039903456" id="230385039903456" accept-charset="utf-8" autocomplete="on"><input type="hidden" name="formID" value="230385039903456" /><input type="hidden" id="JWTContainer" value="" /><input type="hidden" id="cardinalOrderNumber" value="" /><input type="hidden" id="jsExecutionTracker" name="jsExecutionTracker" value="build-date-1715068595154" /><input type="hidden" id="submitSource" name="submitSource" value="unknown" /><input type="hidden" id="buildDate" name="buildDate" value="1715068595154" />
      <div role="main" class="form-all">
        <ul class="form-section page-section">
          <li class="form-line jf-required" data-type="control_fullname" id="id_8"><label class="form-label form-label-top form-label-auto" id="label_8" for="first_8" aria-hidden="false"> Full Name<span class="form-required">*</span> </label>
            <div id="cid_8" class="form-input-wide jf-required" data-layout="full">
              <div data-wrapper-react="true"><span class="form-sub-label-container" style="vertical-align:top" data-input-type="first"><input type="text" id="first_8" name="q8_fullName[first]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 given-name" size="10" data-component="first" aria-labelledby="label_8 sublabel_8_first" required="" value="" /><label class="form-sub-label" for="first_8" id="sublabel_8_first" style="min-height:13px">First Name</label></span><span class="form-sub-label-container" style="vertical-align:top" data-input-type="last"><input type="text" id="last_8" name="q8_fullName[last]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 family-name" size="15" data-component="last" aria-labelledby="label_8 sublabel_8_last" required="" value="" /><label class="form-sub-label" for="last_8" id="sublabel_8_last" style="min-height:13px">Last Name</label></span></div>
            </div>
          </li>
          <li class="form-line" data-type="control_address" id="id_9" data-compound-hint=",,,,Please Select,,Please Select,"><label class="form-label form-label-top form-label-auto" id="label_9" for="input_9_addr_line1" aria-hidden="false"> Address </label>
            <div id="cid_9" class="form-input-wide" data-layout="full">
              <div summary="" class="form-address-table jsTest-addressField">
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-street-line jsTest-address-lineField"><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_addr_line1" name="q9_address[addr_line1]" class="form-textbox form-address-line" data-defaultvalue="" autoComplete="section-input_9 address-line1" data-component="address_line_1" aria-labelledby="label_9 sublabel_9_addr_line1" required="" value="" /><label class="form-sub-label" for="input_9_addr_line1" id="sublabel_9_addr_line1" style="min-height:13px">Street Address</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField" style="display:none"><span class="form-address-line form-address-street-line jsTest-address-lineField"><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_addr_line2" name="q9_address[addr_line2]" class="form-textbox form-address-line" data-defaultvalue="" autoComplete="section-input_9 off" data-component="address_line_2" aria-labelledby="label_9 sublabel_9_addr_line2" value="" /><label class="form-sub-label" for="input_9_addr_line2" id="sublabel_9_addr_line2" style="min-height:13px">Street Address Line 2</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-city-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_city" name="q9_address[city]" class="form-textbox form-address-city" data-defaultvalue="" autoComplete="section-input_9 address-level2" data-component="city" aria-labelledby="label_9 sublabel_9_city" required="" value="" /><label class="form-sub-label" for="input_9_city" id="sublabel_9_city" style="min-height:13px">City</label></span></span><span class="form-address-line form-address-state-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_state" name="q9_address[state]" class="form-textbox form-address-state" data-defaultvalue="" autoComplete="section-input_9 address-level1" data-component="state" aria-labelledby="label_9 sublabel_9_state" value="" /><label class="form-sub-label" for="input_9_state" id="sublabel_9_state" style="min-height:13px">State / Province</label></span></span></div>
                <div class="form-address-line-wrapper jsTest-address-line-wrapperField"><span class="form-address-line form-address-zip-line jsTest-address-lineField "><span class="form-sub-label-container" style="vertical-align:top"><input type="text" id="input_9_postal" name="q9_address[postal]" class="form-textbox form-address-postal" data-defaultvalue="" autoComplete="section-input_9 postal-code" data-component="zip" aria-labelledby="label_9 sublabel_9_postal" required="" value="" /><label class="form-sub-label" for="input_9_postal" id="sublabel_9_postal" style="min-height:13px">Postal / Zip Code</label></span></span></div>
              </div>
            </div>
          </li>
          <li class="form-line form-line-column form-col-1" data-type="control_dropdown" id="id_12"><label class="form-label form-label-top" id="label_12" for="input_12" aria-hidden="false"> Want to Work On ...| </label>
            <div id="cid_12" class="form-input-wide" data-layout="half"> <select class="form-dropdown" id="input_12" name="q12_wantTo" style="width:310px" data-component="dropdown" aria-label="Want to Work On ...|">
                <option value="">Please Select</option>
                <option value="Operation">Operation</option>
                <option value="Supply Managements">Supply Managements</option>
                <option value="Customer Support">Customer Support</option>
                <option value="HR">HR</option>
                <option value="Sales &amp; Marketing">Sales &amp; Marketing</option>
                <option value="Content Writer">Content Writer</option>
                <option value="Developer ( Frontend )">Developer ( Frontend )</option>
                <option value="Developer ( Backend )">Developer ( Backend )</option>
              </select> </div>
          </li>
          <li class="form-line form-line-column form-col-2" data-type="control_phone" id="id_10"><label class="form-label form-label-top" id="label_10" for="input_10_full"> Phone Number </label>
            <div id="cid_10" class="form-input-wide" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="tel" id="input_10_full" name="q10_phoneNumber[full]" data-type="mask-number" class="mask-phone-number form-textbox validate[Fill Mask]" data-defaultvalue="" autoComplete="section-input_10 tel-national" style="width:310px" data-masked="true" placeholder="(000) 000-0000" data-component="phone" aria-labelledby="label_10 sublabel_10_masked" value="" /><label class="form-sub-label" for="input_10_full" id="sublabel_10_masked" style="min-height:13px">Please enter a valid phone number.</label></span> </div>
          </li>
          <li class="form-line form-line-column form-col-3 jf-required" data-type="control_email" id="id_6"><label class="form-label form-label-top" id="label_6" for="input_6" aria-hidden="false"> E-mail<span class="form-required">*</span> </label>
            <div id="cid_6" class="form-input-wide jf-required" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="email" id="input_6" name="q6_email6" class="form-textbox validate[required, Email]" data-defaultvalue="" autoComplete="section-input_6 email" style="width:310px" size="310" placeholder="ex: myname@example.com" data-component="email" aria-labelledby="label_6 sublabel_input_6" required="" value="" /><label class="form-sub-label" for="input_6" id="sublabel_input_6" style="min-height:13px">example@example.com</label></span> </div>
          </li>
          <li class="form-line form-line-column form-col-4" data-type="control_fileupload" id="id_11"><label class="form-label form-label-top" id="label_11" for="input_11" aria-hidden="false"> Upload CV ( Resume ) </label>
            <div id="cid_11" class="form-input-wide" data-layout="full">
              <div class="jfQuestion-fields" data-wrapper-react="true">
                <div class="jfField isFilled">
                  <div class="jfUpload-wrapper">
                    <div class="jfUpload-container">
                      <div class="jfUpload-button-container">
                        <div class="jfUpload-button" aria-hidden="true" tabindex="0" style="display:none" data-version="v2">Browse Files<div class="jfUpload-heading forDesktop">Drag and drop files here</div>
                          <div class="jfUpload-heading forMobile">Choose a file</div>
                        </div>
                      </div>
                    </div>
                    <div class="jfUpload-files-container">
                      <div class="validate[multipleUpload]"><input type="file" id="input_11" name="q11_uploadCv[]" multiple="" class="form-upload-multiple" data-imagevalidate="yes" data-file-accept="pdf, doc, docx, xls, xlsx, csv, txt, rtf, html, zip, mp3, wma, mpg, flv, avi, jpg, jpeg, png, gif" data-file-maxsize="10854" data-file-minsize="0" data-file-limit="" data-component="fileupload" aria-label="Browse Files" /></div>
                    </div>
                  </div>
                  <div data-wrapper-react="true"></div>
                </div><span style="display:none" class="cancelText">Cancel</span><span style="display:none" class="ofText">of</span>
              </div>
            </div>
          </li>
          <li class="form-line jf-required" data-type="control_textarea" id="id_4"><label class="form-label form-label-top form-label-auto" id="label_4" for="input_4" aria-hidden="false"> Describe Your Message :<span class="form-required">*</span> </label>
            <div id="cid_4" class="form-input-wide jf-required" data-layout="full"> <textarea id="input_4" class="form-textarea validate[required]" name="q4_describeYour" style="width:648px;height:80px" data-component="textarea" required="" aria-labelledby="label_4"></textarea> </div>
          </li>
          <li class="form-line" data-type="control_button" id="id_2">
            <div id="cid_2" class="form-input-wide" data-layout="full">
              <div data-align="center" class="form-buttons-wrapper form-buttons-center   jsTest-button-wrapperField"><button id="input_2" type="submit" class="form-submit-button submit-button jf-form-buttons jsTest-submitField" data-component="button" data-content="">Submit Now</button></div>
            </div>
          </li>
          <li style="display:none">Should be Empty: <input type="text" name="website" value="" type="hidden" /></li>
        </ul>
      </div>
      <script>
        JotForm.showJotFormPowered = "new_footer";
      </script>
      <script>
        JotForm.poweredByText = "Powered by Jotform";
      </script><input type="hidden" class="simple_spc" id="simple_spc" name="simple_spc" value="230385039903456" />
      <script type="text/javascript">
        var all_spc = document.querySelectorAll("form[id='230385039903456'] .si" + "mple" + "_spc");
        for (var i = 0; i < all_spc.length; i++)
        {
          all_spc[i].value = "230385039903456-230385039903456";
        }
      </script>
    </form><script type="text/javascript">JotForm.ownerView=true;</script><script type="text/javascript">JotForm.isNewSACL=true;</script> </p>
  `)}
</ul>
 
       </div>
         
<Footersection />
 
  
  
</>
  )
}
