/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'


export default function Blog2() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > How To Join As Merchant | Seller | Partner at Dailyle  ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            <p className="mb-2 text-gray-500 dark:text-gray-400">
  Just Visit <a className='font-bold text-[16px] text-pink-600' href='/StoreMerchant'>
    Join As Merchant Listing  </a>Fill The Form & Get Update Soon For Registration From Us. 
 
  </p>
  
  <a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href='/StoreMerchant'
>
  <span class="text-sm font-medium"> Join Now  </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>

  <ul> <br></br>
  <li>
    <a
      href="tel:917894447766"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-red-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
</svg>




      <span className="text-sm font-medium"> 
       <a href="" className='text-pink-500 text-[14px]' > Increase Your Sale Revenue  
 </a> </span>
    </a>
  </li>

  <li>
    <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
</svg>




      <span className="text-sm font-medium"> 
       <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148" className='text-pink-500 text-[14px]' >   <address className="mt-2 not-italic">  Reach Out To Unknown Customers </address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 1 0 9.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1 1 14.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
</svg>




      <span className="text-sm font-medium">  
       <a href="mailto:contact@dailyle.com" className='text-pink-500 text-[15px]' >Earn Profit From Day 1 </a> </span>
    </a>
  </li>
 
 
</ul>




            </p>
          </div>
          
        </div>
        <div>
        <div className='player-wrapper'>
        <img src="https://res.cloudinary.com/dailyle-company/image/upload/v1705242231/Dailyle%20Banners/Untitled_design_9.png" className="h-210 w-full 
    rounded --300" alt="" /> 
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
