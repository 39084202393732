// eslint-disable-next-line no-unused-vars
import React from 'react'
import parse from 'html-react-parser';

export default function Contactsection() {
  return (
   <>
 

<section className="bg-gray-100">
  <div className="mx-auto max-w-screen-xl px-4 p-5">
    <div className="grid grid-cols-1 gap-x-16 gap-y-4 lg:grid-cols-5">
      <div className="lg:col-span-2 lg:py-1">
        <p className="max-w-xl text-lg">
        Dailyle is Instant Delivery Platform Of Less than 1 Hour.
We Provide Multiple Categories Delivery at Doorstep under 10 Km like Food, Vegetables, Cakes, Sweets, Fruits, Raw Chicken, Raw Fish, Gifts Etc.
Our Mission is to Create Less than 1 Hour Delivery ecosystem worldwide. 
        </p>
        <ul><br></br>
  <li>
    <a
      href="tel:917894447766"
      className="flex items-center gap-2 border-s-[3px] border-blue-500 bg-blue-50 px-4 py-3 text-blue-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 3.75v4.5m0-4.5h-4.5m4.5 0-6 6m3 12c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 0 1 4.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 0 0-.38 1.21 12.035 12.035 0 0 0 7.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 0 1 1.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 0 1-2.25 2.25h-2.25Z" />
</svg>


      <span className="text-sm font-medium"> Phone :
       <a href="tel:917894447766" className='text-pink-500 text-[14px]' > +91 7894447766 </a> </span>
    </a>
  </li>

  <li>
    <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>


      <span className="text-sm font-medium"> 
       <a href="https://www.google.com/maps?q=Dailyle&ftid=0x3a20e3934300f6c9:0x350351fb7e3ed148" className='text-pink-500 text-[14px]' >   <address className="mt-2 not-italic"> Beheramal ,Jharsugdua ,Odisha ,India</address>  </a> </span>
    </a>
  </li>

  <li>
    <a
      href="mailto:contact@dailyle.com"
      className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700"
    >
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>


      <span className="text-sm font-medium"> Support Mail  :
       <a href="mailto:contact@dailyle.com" className='text-pink-500 text-[15px]' > contact@dailyle.com </a> </span>
    </a>
  </li>
 
 
</ul>
 
      </div>

      <div className="rounded-lg bg-transparent	 p-3 shadow-lg lg:col-span-3 lg:p-1">
         
      <ul>
  {parse(`
    <p><script src="https://cdn02.jotfor.ms/static/prototype.forms.js?v=3.3.53665" type="text/javascript"></script>
    <script src="https://cdn03.jotfor.ms/static/jotform.forms.js?v=3.3.53665" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/js/vendor/maskedinput_5.0.9.min.js?v=3.3.53665" type="text/javascript"></script>
    <script src="https://cdn02.jotfor.ms/js/punycode-1.4.1.min.js?v=3.3.53665" type="text/javascript" defer></script>
    <script src="https://cdn01.jotfor.ms/s/umd/e30863682fc/for-form-branding-footer.js?v=3.3.53665" type="text/javascript" defer></script>
    <script src="https://cdn03.jotfor.ms/js/vendor/smoothscroll.min.js?v=3.3.53665" type="text/javascript"></script>
    <script src="https://cdn01.jotfor.ms/js/errorNavigation.js?v=3.3.53665" type="text/javascript"></script>
   
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/stylebuilder/static/form-common.css?v=a856274
    "/>
    <style type="text/css">@media print{*{-webkit-print-color-adjust: exact !important;color-adjust: exact !important;}.form-section{display:inline!important}.form-pagebreak{display:none!important}.form-section-closed{height:auto!important}.page-section{position:initial!important}}</style>
    <link type="text/css" rel="stylesheet" href="https://cdn02.jotfor.ms/themes/CSS/5e6b428acc8c4e222d1beb91.css?v=3.3.53665&themeRevisionID=5f30e2a790832f3e96009402"/>
    <link type="text/css" rel="stylesheet" href="https://cdn03.jotfor.ms/css/styles/payment/payment_styles.css?3.3.53665" />
    <link type="text/css" rel="stylesheet" href="https://cdn01.jotfor.ms/css/styles/payment/payment_feature.css?3.3.53665" />
    <style type="text/css" id="form-designer-style">
        /* Injected CSS Code */
    .form-label.form-label-auto {
            
          display: block;
          float: none;
          text-align: left;
          width: 100%;
        
          }
        /* Injected CSS Code */
    </style>
    
    <form class="jotform-form" onsubmit="return typeof testSubmitFunction !== 'undefined' && testSubmitFunction();" action="https://submit.jotform.com/submit/230383762760458" method="post" name="form_230383762760458" id="230383762760458" accept-charset="utf-8" autocomplete="on"><input type="hidden" name="formID" value="230383762760458" /><input type="hidden" id="JWTContainer" value="" /><input type="hidden" id="cardinalOrderNumber" value="" /><input type="hidden" id="jsExecutionTracker" name="jsExecutionTracker" value="build-date-1715067944868" /><input type="hidden" id="submitSource" name="submitSource" value="unknown" /><input type="hidden" id="buildDate" name="buildDate" value="1715067944868" />
      <div role="main" class="form-all">
        <ul class="form-section page-section">
          <li class="form-line" data-type="control_radio" id="id_3"><label class="form-label form-label-top form-label-auto" id="label_3" aria-hidden="false"> Contact For </label>
            <div id="cid_3" class="form-input-wide" data-layout="full">
              <div class="form-multiple-column" data-columncount="3" role="group" aria-labelledby="label_3" data-component="radio"><span class="form-radio-item"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_0" name="q3_contactFor" value="Order Cancellation" /><label id="label_input_3_0" for="input_3_0">Order Cancellation</label></span><span class="form-radio-item"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_1" name="q3_contactFor" value="Feedback" /><label id="label_input_3_1" for="input_3_1">Feedback</label></span><span class="form-radio-item"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_2" name="q3_contactFor" value="Investors" /><label id="label_input_3_2" for="input_3_2">Investors</label></span><span class="form-radio-item" style="clear:left"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_3" name="q3_contactFor" value="Seller / Vendor / Partner" /><label id="label_input_3_3" for="input_3_3">Seller / Vendor / Partner</label></span><span class="form-radio-item"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_4" name="q3_contactFor" value="Delivery Agents" /><label id="label_input_3_4" for="input_3_4">Delivery Agents</label></span><span class="form-radio-item"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_5" name="q3_contactFor" value="Jobs" /><label id="label_input_3_5" for="input_3_5">Jobs</label></span><span class="form-radio-item" style="clear:left"><span class="dragger-item"></span><input type="radio" aria-describedby="label_3" class="form-radio" id="input_3_6" name="q3_contactFor" value="Others" /><label id="label_input_3_6" for="input_3_6">Others</label></span></div>
            </div>
          </li>
          <li class="form-line jf-required" data-type="control_textarea" id="id_4"><label class="form-label form-label-top" id="label_4" for="input_4" aria-hidden="false"> Describe Your Message :<span class="form-required">*</span> </label>
            <div id="cid_4" class="form-input-wide jf-required" data-layout="full"> <textarea id="input_4" class="form-textarea validate[required]" name="q4_describeYour" style="width:648px;height:77px" data-component="textarea" required="" aria-labelledby="label_4"></textarea> </div>
          </li>
          <li class="form-line jf-required" data-type="control_fullname" id="id_8"><label class="form-label form-label-top form-label-auto" id="label_8" for="first_8" aria-hidden="false"> Name<span class="form-required">*</span> </label>
            <div id="cid_8" class="form-input-wide jf-required" data-layout="full">
              <div data-wrapper-react="true"><span class="form-sub-label-container" style="vertical-align:top" data-input-type="first"><input type="text" id="first_8" name="q8_name[first]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 given-name" size="10" data-component="first" aria-labelledby="label_8 sublabel_8_first" required="" value="" /><label class="form-sub-label" for="first_8" id="sublabel_8_first" style="min-height:13px">First Name</label></span><span class="form-sub-label-container" style="vertical-align:top" data-input-type="last"><input type="text" id="last_8" name="q8_name[last]" class="form-textbox validate[required]" data-defaultvalue="" autoComplete="section-input_8 family-name" size="15" data-component="last" aria-labelledby="label_8 sublabel_8_last" required="" value="" /><label class="form-sub-label" for="last_8" id="sublabel_8_last" style="min-height:13px">Last Name</label></span></div>
            </div>
          </li>
          <li class="form-line form-line-column form-col-1" data-type="control_phone" id="id_10"><label class="form-label form-label-top" id="label_10" for="input_10_full"> Phone Number </label>
            <div id="cid_10" class="form-input-wide" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="tel" id="input_10_full" name="q10_phoneNumber[full]" data-type="mask-number" class="mask-phone-number form-textbox validate[Fill Mask]" data-defaultvalue="" autoComplete="section-input_10 tel-national" style="width:310px" data-masked="true" placeholder="(000) 000-0000" data-component="phone" aria-labelledby="label_10 sublabel_10_masked" value="" /><label class="form-sub-label" for="input_10_full" id="sublabel_10_masked" style="min-height:13px">Please enter a valid phone number.</label></span> </div>
          </li>
          <li class="form-line form-line-column form-col-2 jf-required" data-type="control_email" id="id_6"><label class="form-label form-label-top" id="label_6" for="input_6" aria-hidden="false"> E-mail<span class="form-required">*</span> </label>
            <div id="cid_6" class="form-input-wide jf-required" data-layout="half"> <span class="form-sub-label-container" style="vertical-align:top"><input type="email" id="input_6" name="q6_email6" class="form-textbox validate[required, Email]" data-defaultvalue="" autoComplete="section-input_6 email" style="width:310px" size="310" placeholder="ex: myname@example.com" data-component="email" aria-labelledby="label_6 sublabel_input_6" required="" value="" /><label class="form-sub-label" for="input_6" id="sublabel_input_6" style="min-height:13px">example@example.com</label></span> </div>
          </li>
          <li class="form-line" data-type="control_button" id="id_2">
            <div id="cid_2" class="form-input-wide" data-layout="full">
              <div data-align="center" class="form-buttons-wrapper form-buttons-center   jsTest-button-wrapperField"><button id="input_2" type="submit" class="form-submit-button submit-button jf-form-buttons jsTest-submitField" data-component="button" data-content="">Submit Now</button></div>
            </div>
          </li>
          <li style="display:none">Should be Empty: <input type="text" name="website" value="" type="hidden" /></li>
        </ul>
      </div>
      <script>
        JotForm.showJotFormPowered = "new_footer";
      </script>
      <script>
        JotForm.poweredByText = "Powered by Jotform";
      </script><input type="hidden" class="simple_spc" id="simple_spc" name="simple_spc" value="230383762760458" />
      <script type="text/javascript">
        var all_spc = document.querySelectorAll("form[id='230383762760458'] .si" + "mple" + "_spc");
        for (var i = 0; i < all_spc.length; i++)
        {
          all_spc[i].value = "230383762760458-230383762760458";
        }
      </script>
    </form><script type="text/javascript">JotForm.ownerView=true;</script><script type="text/javascript">JotForm.isNewSACL=true;</script>   </p>
  `)}
</ul>

      </div>
    </div>
  </div>
</section>
   </>
  )
}
