/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../Home/Navbarsection'
import Footersection from '../Home/Footersection'
import ReactPlayer from 'react-player' 

export default function Blog1() {
  return (
   <>
   <Navbarsection/>
   <div class="p-6  text-base">
 
 
      <div className="grid gap-5 row-gap-8 lg:grid-cols-2">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mb-6">
          <h1  className="mx-auto text-center  font-bold lg:text-[19px]  sm:text-3xl justify-self-auto " > What is Dailyle ? </h1>
 <br></br>
 
          
            <p className="text-base text-gray-700 md:text-lg">
            Dailyle is Instant Delivery Platform Of Less than 1 Hour. We Provide Multiple Categories Delivery at Doorstep under 10 Km like Food, Vegetables, Cakes, Sweets, Fruits, Raw Chicken, Raw Fish, Gifts Etc. Our Mission is to Create Less than 1 Hour Delivery ecosystem worldwide. 

            </p>
          </div>
          
        </div>
        <div>
        <div className='mr-3 player-wrapper'>
            <ReactPlayer
            className='react-player videoFrame'
            url= 'https://res.cloudinary.com/dailyle-company/video/upload/v1705240770/Dailyle%20Banners/Videos/Untitled_design_6.mp4'
            width='100%'
            height='300px'
           /*  height='100%' */
            controls = {true}  
            light="https://res.cloudinary.com/dailyle-company/image/upload/v1705256741/Dailyle%20Banners/pic_youtube.png" 
            autoPaused = {true}
            autoplay = {true}
            />
        </div>
 
        </div>
      </div>
    </div>
 
   <Footersection/>
   </>
  )
}
