 
import Navbarsection from "../views/Home/Navbarsection" 
// eslint-disable-next-line no-unused-vars
import Agentsections from "../views/Join/Agentsections"
import Footersection from "../views/Home/Footersection"
    
export default function agent() {
  return (
<>
 
<Navbarsection />
  <Agentsections/>
 
<Footersection />
 
  
  
</>
  )
}

