/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React from "react";
import { 
  Button, 
  ListItem,
  ListItemPrefix,
  ListItemSuffix, 
} from "@material-tailwind/react";
  
export default function Joinsection() {
  return (
   <> 
   
   <section class="mb-[42px] text-gray-600 body-font">
   
  <div class="container px-4 mt-6 mx-auto flex flex-wrap">
    <div class="mx-auto">
      <div class="mb-9 ">
      <h3 class="text-center text-2xl font-bold sm:text-3xl"> Join As  </h3>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2">
        <div class="px-2 w-1/2">
          <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-purple-600 bg-opacity-40 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-25 absolute inset-0" src="https://images.pexels.com/photos/9461800/pexels-photo-9461800.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          alt="pic"
        />
            <div class="text-center relative z-10 w-full">
              <h2 class="text-[18px] text-white font-bold title-font mb-2"> Delivery Agent Join </h2>
              <p class="text-white leading-relaxed"> Earn Money and Choose Your Work Time . Join Now  </p> 
              <a href="/Deliveryagent" >  <Button className="mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="/Deliveryagent" className="text-[14px] mr-[2px]" > Join    </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>
        <div class="px-2 w-1/2">
        <div class="flex flex-wrap w-full bg-gradient-to-t from-black  bg-purple-600 bg-opacity-30 sm:py-24 py-16 sm:px-10 px-6 relative">
          <img
          class="w-full object-cover h-full object-center block opacity-20 absolute inset-0" src="https://res.cloudinary.com/dailyleadmin/image/upload/v1704770538/Banners/pexels-hong-son-19647394_cattpg.jpg"
          alt="pic"
        />
            <div class="text-center relative z-10 w-full">
              <h2 class="text-[18px] text-white font-bold title-font mb-2"> Merchant / Store Listing </h2>
              <p class="text-white leading-relaxed"> Increase Your Sale Potential and Reach To Unknown Customers </p>
              <a href="/StoreMerchant" >  <Button className="mt-3" size="sm">
        <ListItem className="rounded-none py-1.5 px-3 text-sm font-normal text-blue-gray-700  hover:text-white  focus:text-white">
         <a href="/StoreMerchant"  className="text-[14px] mr-[2px]" > Join  </a>

  <ListItemSuffix>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
</svg>

          </ListItemSuffix>
        </ListItem> 
        </Button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
   
</section>
 
   </>
  )
}
 
