/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */
 
 
import Navbarsection from "../views/Home/Navbarsection"  
 
import Footersection from "../views/Home/Footersection"
  
import Aboutusection
 from "../views/About/Aboutusection"
export default function About() {
  return (
<>
 
<Navbarsection />
 
<Aboutusection />
 
 
 
<Footersection />
 
  
  
</>
  )
}
