/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
// eslint-disable-next-line no-unused-vars
import ReactPlayer from 'react-player' 


export default function Aboutusection() {
  return (
  <> 
  <section>
  <div className="mx-auto max-w-screen-xl px-4 py-2 sm:px-6  lg:px-3 lg:py-3 lg:mb-3">
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-16">
      <div className="relative h-64 overflow-hidden rounded-lg sm:h-8 lg:order-last lg:h-full">
     
      <div className='mr-5 player-wrapper'>
            <ReactPlayer
            className='react-player videoFrame'
            url= 'https://res.cloudinary.com/dailyle-company/video/upload/v1705240770/Dailyle%20Banners/Videos/Untitled_design_6.mp4'
            width='100%'
            height='280px'
           /*  height='100%' */
            controls = {true}  
            light="https://res.cloudinary.com/dailyle-company/image/upload/v1705256741/Dailyle%20Banners/pic_youtube.png" 
            autoPaused = {true}
            autoplay = {true}
            />
        </div>

      </div>

      <div className="ml-6 mt-6 ">
        <h2 className="text-[18px] font-bold sm:text-4xl"> About <a className="text-purple-700" > Dailyle </a> </h2>

        <p className="mt-4 text-gray-600">
        Dailyle is Instant Delivery Platform Of Less than 1 Hour. We Provide Multiple Categories Delivery at Doorstep under 10 Km like Food, Vegetables, Cakes, Sweets, Fruits, Raw Chicken, Raw Fish, Gifts Etc. Our Mission is to Create Less than 1 Hour Delivery ecosystem worldwide.
 
        </p>
     
<a
  class="mt-8 inline-flex items-center gap-2 rounded border border-indigo-600 bg-indigo-600 px-8 py-3 text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500"
  href="https://play.google.com/store/apps/dev?id=5032443138271103580&hl=en_IN"
>
  <span class="text-sm font-medium"> 
  Download & Order Online
      </span>

  <svg
    class="h-5 w-5 rtl:rotate-180"
    xmlns="https://res.cloudinary.com/dailyleadmin/image/upload/v1637069073/Logos/MAIN_DAILYLE_xitqhf.png"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M17 8l4 4m0 0l-4 4m4-4H3"
    />
  </svg>
</a>

       
      </div>
    </div><br></br>
  </div>
</section>
  </>
  )
}

 